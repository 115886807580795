<template>
  <div
      :style="getStyle"
      ref="mescroll"
      class="mescroll"
  >
    <slot></slot>
    <div v-if="isEmpty">
      <slot name="empty">
        <van-empty description="暂无相关数据"/>
      </slot>
    </div>
  </div>
</template>
<script>
import MeScroll from 'mescroll.js'
import 'mescroll.js/mescroll.min.css'
import XEUtils from "xe-utils";

export default {
  name: 'ListPage',
  props: {
    top: {
      type: String,
      default() {
        return '0px'
      }
    },

    bottom: {
      type: String,
      default() {
        return '0px'
      }
    },
    pageSize: {
      type: Number,
      default() {
        return 10
      }
    },
    htmlNodata: {
      type: String,
      default() {
        return '<p class="upwarp-nodata">-- 没有更多了 --</p>'
      }
    }
  },
  data() {
    return {
      lastScrollTop: 0, // 路由切换时滚动条的位置
      lastBounce: null, // 路由切换时是否禁止ios回弹
      toTop: {
        warpId: null,
        src: null,
        html: null,
        offset: 1000,
        warpClass: "mescroll-totop",
        showClass: "mescroll-fade-in",
        hideClass: "mescroll-fade-out",
        duration: 300,
        supportTap: false,
        btnClick: null
      },
      pageData: {
        number: 1
      },
      isEmpty: false,
      mescroll: null,
      mescrollDown: {
        auto: true,//是否在初始化完毕之后自动执行一次下拉刷新的回调
        use: false,//是否启用下拉刷新
        callback: this.downCallback //下拉刷新的回调,别写成downCallback(),多了括号就自动执行方法了
      },
      mescrollUp: {
        auto: true,//是否在初始化完毕之后自动执行一次下拉刷新的回调
        use: true,//是否启用上拉加载
        callback: this.upCallback, //上拉加载的回调
        //以下是一些常用的配置,当然不写也可以的.
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: this.pageSize //每页数据条数,默认10
        },
        htmlNodata: `${this.htmlNodata}`,
        noMoreSize: 5, //如果列表已无数据,可设置列表的总数量要大于5才显示无更多数据;
        // 避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        // 这就是为什么无更多数据有时候不显示的原因.
        // toTop: {
        //   //回到顶部按钮
        //   src: "../img/mescroll-totop.png", //图片路径,默认null,支持网络图
        //   offset: 1000 //列表滚动1000px才显示回到顶部按钮
        // },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "mei-you-shu-ju", //父布局的id (1.3.5版本支持传入dom元素)
          // icon: "../img/mescroll-empty.png", //图标,默认null,支持网络图
          tip: "暂无相关数据~" //提示
        },
        lazyLoad: {
          use: true, // 是否开启懒加载,默认false
          attr: 'imgurl' // 标签中网络图的属性名 : <img imgurl='网络图  src='占位图''/>
        }
      }
    }
  },
  mounted() {
    this.mescroll = new MeScroll(this.$refs.mescroll, {
      up: this.mescrollUp,
      toTop: this.toTop,
      down: this.mescrollDown
    })
  },

  methods: {

    downCallback(page) {
      console.log('load', {page: 1})
    },
    upCallback({num, size}) {
      this.pageData.num = num
      this.pageData.size = size
      this.$emit('load', {num, size})
      // this.beforeRouteLeave()
    },
    endSuccess(dataNumber, totalSize) {
      this.$nextTick(() => {
        if (dataNumber === 0 && this.pageData.num === 1) {
          this.isEmpty = true
          this.mescroll.endSuccess(dataNumber, false);
        } else {
          this.isEmpty = false
          this.mescroll.endSuccess(dataNumber, dataNumber > 0);
        }
        // this.beforeRouteEnter()
      })
    },
    resetData() {
      this.reset()
    },
    reset() {
      this.mescroll.resetUpScroll();
    },
    beforeRouteEnter() {

      if (this.mescroll) {
        // 滚动到之前列表的位置
        if (this.lastScrollTop) {

          this.mescroll.setScrollTop(this.lastScrollTop)
          setTimeout(() => { // 需延时,因为setScrollTop内部会触发onScroll,可能会渐显回到顶部按钮
            this.mescroll.setTopBtnFadeDuration(0) // 设置回到顶部按钮显示时无渐显动画
          }, 16)
        }
        // 恢复到之前设置的isBounce状态
        if (this.lastBounce != null) this.mescroll.setBounce(this.lastBounce)
      }
    },
    beforeRouteLeave(offset = null) {
      if (this.mescroll) {
        this.lastScrollTop = XEUtils.isNumber(offset) ? offset : this.mescroll.getScrollTop() // 记录当前滚动条的位置
        this.mescroll.hideTopBtn(0) // 隐藏回到顶部按钮,无渐隐动画
        this.lastBounce = this.mescroll.optUp.isBounce // 记录当前是否禁止ios回弹
        this.mescroll.setBounce(true) // 允许bounce
      }
    }
  },
  computed: {
    getStyle() {
      return {
        top: this.top,
        bottom: this.bottom
      }
    }
  }

}
</script>


<style scoped>
.mescroll {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto; /*如设置bottom:50px,则需*/
  /*background-color: red;*/
}
</style>
